import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';

const LotGradingCertificates = () => (
  <Layout
    title="Lot Grading Surveys & Certificates Calgary & Red Deer | Axiom Geomatics"
    description="A Lot Grading Survey is a type of municipal survey designed to determine which direction water will drain when it lands on the property."
    keywords="municipal survey, construction survey, lot grading certificate, drainage plan, lot coverage plan, draining survey, grade, slope, water pooling, water drainage, landscape survey"
  >
    <main>
      <h1>Lot Grading Surveys & Certificates Calgary & Area</h1>
      <p>
        A <strong>Lot Grading Survey</strong> is a type of municipal survey that shows the
        predominant drainage pattern of the parcel. This survey should be completed after the
        house/garage/structure is built and landscaping has been completed.
      </p>

      <p>
        To meet local{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/plot-plans">Plot Plan</a>{' '}
        requirements the water must drain away from the building on all sides. If it does not the
        builder/landscaper will be required to perform remedial work.
      </p>

      <p>
        After the survey is complete, and when all water drains away from the property, an{' '}
        <a href="https://axiomgeomatics.ca/about-us">Alberta Land Surveyor at Axiom Geomatics</a>{' '}
        can issue a Lot Grading Certificate, these are frequently required by municipalities and
        insurance companies.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#need-one">Do I need a Lot Grading Certificate?</Link>
        </li>
        <li>
          <Link to="#drain-wrong-way">
            What if the water drains away from my property, but not in the direction of the Plot
            Plan?
          </Link>
        </li>
      </ul>

      <h3 id="need-one">Do I need a Lot Grading Certificate?</h3>
      <p>
        This requirement varies by municipality and also within the municipality, e.g. in the City
        of Calgary only new developments require a Lot Grading Certificate.
      </p>

      <h3 id="drain-wrong-way">
        What if the water drains away from my property, but not in the direction of the Plot Plan?
      </h3>
      <p>
        Usually this isn't a problem as the primary concern is for water to move away from the
        structure, thus protecting the property. There are a few occasions where this is not
        suitable and remediation work will be required. This varies on a case-by-case basis.
      </p>
    </main>
  </Layout>
);

export default LotGradingCertificates;
